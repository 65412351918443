import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { TileCarousel } from 'components/TileCarousel/TileCarousel';
import { CmsApiRecommendedActionsBlockData } from 'modules/cms/api/types/blocks/CmsApiRecommendedActionsBlock';
import { useSearchAlgoliaRecommendedVolops } from 'modules/search/hooks/useSearchAlgoliaRecommendedVolops';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { useMainSelector } from 'store/hooks/useMainSelector';

type Props = {
  data: CmsApiRecommendedActionsBlockData;
};

export function CmsRecommendedActionsBlockContent({ data }: Props) {
  const { headline, volopFunction, areaOfFocus, locale } = data;

  const searchLocation = useMainSelector(
    (state) => state.mainSearch.searchLocation,
  ) as SearchLocation;

  const { data: recommendedActions, isLoading } =
    useSearchAlgoliaRecommendedVolops(searchLocation, {
      volopFunction,
      areaOfFocus,
      locale,
    });

  if (isLoading) return <LoadingSection />;

  const additionalAnalyticsProps: Record<string, string> = {};

  if (volopFunction) additionalAnalyticsProps.volop_function = volopFunction;
  if (areaOfFocus) additionalAnalyticsProps.area_of_focus = areaOfFocus;
  if (locale && locale !== 'all') additionalAnalyticsProps.locale = locale;

  return (
    <TileCarousel
      headline={headline}
      results={recommendedActions}
      seeAll={{
        text:
          /* TRANSLATORS: Link to actions search */
          getText('See All Volunteer Opportunities'),
        action: {
          type: 'link',
          url: searchGetVolopsRoutePath({ lang: CURRENT_LOCALE }),
        },
      }}
      listingType="ACTION"
      additionalAnalyticsProps={additionalAnalyticsProps}
    />
  );
}
